import React, {useContext, useEffect, useState} from "react";
import {Route} from "react-router-dom";

import {
    apiEVDashboardCardData,
    apiGetDeviceDetailsGrafanaList,
    apiGetDeviceDetailsTableData,
    apiGetTripsTableData
} from "../../crud";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {useSubheader} from "../../../../_metronic/layout";
import DatePicker from 'react-datepicker';
import {Formik} from "formik";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {Button} from "@material-ui/core";
import * as Utils from "../../Utils/Utils";
import {DeviceDetailsMap} from "./DeviceDetailsMap";
import {ReportDateRangeSelector} from "./ReportDateRangeSelector";
import {DashboardBarChartCard} from "../dashboard/cards/DashboardBarChartCard";
import {DashboardLineChartCard} from "../dashboard/cards/DashboardLineChartCard";
import {DashboardAlertsCard} from "../dashboard/cards/DashboardAlertsCard";

export function DeviceDetailsPage({history, id}) {

    const [grafanaURL, setGrafanaURL] = useState([]);
    const [loading, setLoading] = useState(false);
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);
    const subHeader = useSubheader();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tableData, setTableData] = useState([]);
    const [tripsData, setTripsData] = useState([]);
    const {getOrgType} = useContext(AuthContext);
    const [reload, setReload] = useState(0);
    const [data, setData] = useState({});

    function reloadData() {
        setReload(reload + 1);
    }

    useEffect(() => {

        let params = {
            imei: id, date: selectedDate
        };

        apiEVDashboardCardData(params).then(({data}) => {
            setData(data)
        })
            .catch(() => {
            });

    }, [selectedDate]);

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");

        subHeader.setTitle("Device Details");
        subHeader.setBreadcrumbs([{
            "pathname": "/devices", "title": "Devices"
        }, {
            "title": "Device Details"
        }]);

    }, [auth]);

    function commandsPage() {
        history.push(`/commands/${id}`);
    }

    function routePlaybackPage() {
        history.push(`/routePlayback/${id}`);
    }

    function downloadReport() {
        history.push(`/deviceDetails/reports/new/${id}`);
    }

    useEffect(() => {
        let params = {
            date: selectedDate
        };

        apiGetDeviceDetailsGrafanaList(id, params).then(({data}) => {
            setGrafanaURL(data.entities);
        })
            .catch(() => {
            });

    }, []);

    function openChild(id) {
        history.push("/tripDetailsPage/" + id);
    }

    useEffect(() => {
        let params = {
            date: selectedDate
        };

        apiGetDeviceDetailsTableData(id, params).then(({data}) => {
            setTableData(data);
        })
            .catch(() => {
            });

    }, []);

    useEffect(() => {
        apiGetTripsTableData(id).then(({data}) => {
            setTripsData(data);
        })
            .catch(() => {
            });

    }, []);

    return (<>
        <Route path="/deviceDetails/reports/new/:id">
            {({history, match}) => (<ReportDateRangeSelector
                history={history}
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                    history.push(`/deviceDetails/${match && match.params.id}`);
                }}
                reloadData={reloadData}
            />)}
        </Route>

        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-fluid">
                <div className="card card-custom card-stretch ">
                    <div className="card-header row row-marginless align-items-center flex-wrap py-5 h-auto">
                        <Formik
                            initialValues={{}}
                            onSubmit={() => {

                                let params = {
                                    date: selectedDate
                                };

                                apiGetDeviceDetailsGrafanaList(id, params).then(({data}) => {
                                    setGrafanaURL(data.entities);
                                    showToast("Please Wait, Loading new data", TOAST_SUCCESS);
                                })
                                    .catch(() => {
                                    });
                            }}
                        >

                            {({
                                  values, handleSubmit, handleBlur, handleChange, setFieldValue,
                              }) => (<form onSubmit={handleSubmit} className="form form-label-right col-sm-8">
                                <div className="form-group row ">

                                    <div className="col-lg-6">
                                        <DatePicker className="form-control"
                                                    selected={selectedDate}
                                                    dateFormat="dd MMM yyyy"
                                                    onChange={(e) => {
                                                        setSelectedDate(e);
                                                        handleSubmit();
                                                    }}
                                        />

                                        <small className="form-text text-muted">
                                            <b>Filter</b> by Date
                                        </small>
                                    </div>
                                </div>
                            </form>)}
                        </Formik>

                        <div className="row">

                            <Button onClick={() => downloadReport()}
                                    className="btn btn-sm btn-light-primary font-weight-bold ml-2 mr-2">
                                <span className="flaticon2-download">&nbsp;&nbsp;Download Reports</span>
                            </Button>

                            <Button onClick={() => commandsPage()}
                                    className="btn btn-sm btn-light-primary font-weight-bold ml-2 mr-2">
                                <span className="flaticon2-settings">&nbsp;&nbsp;Commands</span>
                            </Button>

                            <Button onClick={() => routePlaybackPage()}
                                    className="btn btn-sm btn-light-primary font-weight-bold ml-2 mr-2">
                                <span className="flaticon2-map">&nbsp;&nbsp;Route Playback</span>
                            </Button>
                        </div>
                    </div>
                </div>

                <br/>
                <div className="fw-bolder bg-white card gs-0 gy-4 p-4">
                    <b>Live Fleet Data : {tableData[55]}</b>
                </div>
                <br/>

                {(getOrgType() === 1 || getOrgType() === 3) &&
                    <div className="row">

                        <div className="col-lg-3 col-xxl-3">
                            <iframe
                                src={grafanaURL[28]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-3 col-xxl-3">
                            <iframe
                                src={grafanaURL[29]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-3 col-xxl-3">
                            <iframe
                                src={grafanaURL[30]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-3 col-xxl-3">
                            <iframe
                                src={grafanaURL[31]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-3 col-xxl-3">
                            <iframe
                                src={grafanaURL[32]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-3 col-xxl-3">
                            <iframe
                                src={grafanaURL[33]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-3 col-xxl-3">
                            <iframe
                                src={grafanaURL[17]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-3 col-xxl-3">
                            <iframe
                                src={grafanaURL[1]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-6 col-xxl-6">
                            <iframe
                                src={grafanaURL[38]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-6 col-xxl-6">
                            <iframe
                                src={grafanaURL[39]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-6 col-xxl-6">
                            <iframe
                                src={grafanaURL[40]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-6 col-xxl-6">
                            <iframe
                                src={grafanaURL[41]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>
                    </div>
                }

                {(getOrgType() === 1 || getOrgType() === 3) &&
                    <div className="row">
                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[34]}
                                width="100%" height="120px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[35]}
                                width="100%" height="120px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[36]}
                                width="100%" height="120px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[37]}
                                width="100%" height="120px" frameBorder="0"/>
                        </div>
                    </div>
                }
                
                <div className="row">

                    <div className="col-lg-6 col-xxl-6 mb-2">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            {Object.keys(data).length > 0 && data.mvid_vehicles && data.mvid_vehicles.data ? (
                                <DashboardBarChartCard title={"Moving/Idle times"} data={data.mvid_vehicles}
                                                       unit={"mins"}/>) : (
                                <div style={{textAlign: 'center'}}>No data available</div>)}
                        </div>
                    </div>

                    <div className="col-lg-6 col-xxl-6 mb-2">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>

                            {Object.keys(data).length > 0 && data.distance_travelled && data.distance_travelled.data ? (
                                <DashboardLineChartCard title={"Distance Travelled"} data={data.distance_travelled}
                                                        unit={"kms"}/>) : (
                                <div style={{textAlign: 'center'}}>No data available</div>)}

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-xxl-12 mb-2">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <div className="card card-flush h-md-100 p-6"
                                 style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                <DashboardAlertsCard title={"Vehicle Alerts"} history={history} reload={reload}
                                                     selectedDate={selectedDate} imei={id}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">

                    {/*{getOrgType() !== 1 && <>*/}
                    {/*    <div className="col-lg-4 col-xxl-4">*/}
                    {/*        <iframe*/}
                    {/*            src={grafanaURL[15]}*/}
                    {/*            width="100%" height="300px" frameBorder="0"/>*/}
                    {/*    </div>*/}

                    {/*    <div className="col-lg-4 col-xxl-4">*/}
                    {/*        <iframe*/}
                    {/*            src={grafanaURL[16]}*/}
                    {/*            width="100%" height="300px" frameBorder="0"/>*/}
                    {/*    </div>*/}

                    {/*    <div className="col-lg-4 col-xxl-4">*/}
                    {/*        <iframe*/}
                    {/*            src={grafanaURL[27]}*/}
                    {/*            width="100%" height="300px" frameBorder="0"/>*/}
                    {/*    </div>*/}
                    {/*</>}*/}

                    <div className="col-lg-12 col-xxl-12">
                        <DeviceDetailsMap id={id}
                                          selectedDate={selectedDate}
                                          history={history}/>
                    </div>

                    <div className="col-lg-6 col-xxl-6 mb-2">
                        <div className="card card-custom card-stretch mb-2 p-4">
                            <strong style={{textAlign: 'center'}} className="pb-4">Recent Trips</strong>
                            <table className="table table table-bordered">
                                <tbody>

                                <tr>
                                    <td><strong>From</strong></td>
                                    <td><strong>To</strong></td>
                                    <td><strong>Duration</strong></td>
                                    <td><strong>Distance</strong></td>
                                    <td><strong>Start SOC</strong></td>
                                    <td><strong>End SOC</strong></td>
                                    <td><strong>Status</strong></td>
                                </tr>

                                {tripsData.map(function (trip, index) {
                                    return <tr key={index}>
                                        <td>{Utils.getFormattedTime(trip.tripStartTime, "DD MMM, hh:mm a")}</td>
                                        <td>{Utils.getFormattedTime(trip.tripEndTime, "DD MMM, hh:mm a")}</td>
                                        <td>{trip.totalTime} Mins</td>
                                        <td>{trip.totalDistance} kms</td>
                                        <td>{trip.startSOCReading}%</td>
                                        <td>{trip.endSOCReading}%</td>
                                        {trip.tripStatus === 1 && <td onClick={() => openChild(trip.id)}><a href="#"
                                                                                                            className="d-flex align-items-center text-primary text-hover-warning font-weight-bold mr-3">COMPLETED</a>
                                        </td>}

                                        {trip.tripStatus === 0 && <td onClick={() => openChild(trip.id)}><a href="#"
                                                                                                            className="d-flex align-items-center text-danger text-hover-warning font-weight-bold mr-3">IN
                                            PROGRESS</a></td>}
                                    </tr>
                                })}
                                </tbody>
                            </table>
                            <small style={{textAlign: 'right'}}>For more trips, Visit trips page.</small>

                        </div>
                    </div>

                    <div className="col-lg-6 col-xxl-6 mb-2">
                        <div className="card card-custom card-stretch mb-2 p-4">
                            <strong style={{textAlign: 'center'}} className="pb-4">Device Details</strong>
                            <table className="table table table-bordered">
                                <tbody>
                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-barcode"></i></th>
                                    <td><strong>IMEI</strong></td>
                                    <td><strong>{tableData[0]}</strong></td>
                                </tr>
                                {/*<tr>*/}
                                {/*    <th width='10px' scope="row"><i className="fas fa-ad"></i></th>*/}
                                {/*    <td><strong>SIM No</strong></td>*/}
                                {/*    <td><strong>{tableData[56]}</strong></td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <th width='10px' scope="row"><i className="fas fa-mobile"></i></th>*/}
                                {/*    <td><strong>Device Type</strong></td>*/}
                                {/*    <td><strong>{tableData[57]}</strong></td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <th width='10px' scope="row"><i className="fas fa-bell"></i></th>*/}
                                {/*    <td><strong>F/W Version</strong></td>*/}
                                {/*    <td><strong>{tableData[58]}</strong></td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <th width='10px' scope="row"><i className="fas fa-bell"></i></th>*/}
                                {/*    <td><strong>H/W Version</strong></td>*/}
                                {/*    <td><strong>{tableData[59]}</strong></td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-clock"></i></th>
                                    <td><strong>Last Updated</strong></td>
                                    <td>{tableData[1]}</td>
                                </tr>

                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-shuttle-van"></i></th>
                                    <td><strong>Speed (km/hr)</strong></td>
                                    <td>{tableData[2]}</td>
                                </tr>

                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-signal"></i></th>
                                    <td><strong>Status</strong></td>
                                    <td>{tableData[3]}</td>
                                </tr>

                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-road"></i></th>
                                    <td><strong>Odometer (Kms)</strong></td>
                                    <td>{tableData[8]}</td>
                                </tr>
                                {getOrgType() === 1 &&
                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-bacon"></i></th>
                                        <td><strong>Voltage (V)</strong></td>
                                        <td>{tableData[60]}</td>
                                    </tr>
                                }

                                {getOrgType() !== 1 && <>
                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-bacon"></i></th>
                                        <td><strong>Battery Voltage (V)</strong></td>
                                        <td>{tableData[4]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-asterisk"></i></th>
                                        <td><strong>Battery Current (A)</strong></td>
                                        <td>{tableData[5]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-car-battery"></i></th>
                                        <td><strong>SOC</strong></td>
                                        <td>{tableData[6]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-bolt"></i></th>
                                        <td><strong>SOH</strong></td>
                                        <td>{tableData[7]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-temperature-high"></i>
                                        </th>
                                        <td><strong>Battery Temperature (°C)</strong></td>
                                        <td>{tableData[9]}</td>
                                    </tr>
                                </>}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="col-lg-6 col-xxl-6">
                        <iframe
                            src={grafanaURL[5]}
                            width="100%" height="300px" frameBorder="0"/>
                    </div>

                    <div className="col-lg-6 col-xxl-6">
                        <iframe
                            src={grafanaURL[19]}
                            width="100%" height="300px" frameBorder="0"/>
                    </div>

                </div>

                {getOrgType() !== 1 && <>
                    <br/>
                    <br/>

                    <div className="fw-bolder bg-white card gs-0 gy-4 p-4">
                        <b>Live Battery Data</b>
                    </div>
                    <br/>

                    <div className="row">

                        <div className="col-lg-2 col-xxl-2">
                            <iframe
                                src={grafanaURL[14]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-2 col-xxl-2">
                            <iframe
                                src={grafanaURL[20]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-2 col-xxl-2">
                            <iframe
                                src={grafanaURL[2]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-2 col-xxl-2">
                            <iframe
                                src={grafanaURL[3]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-2 col-xxl-2">
                            <iframe
                                src={grafanaURL[8]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-2 col-xxl-2">
                            <iframe
                                src={grafanaURL[21]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-4 col-xxl-4">
                            <iframe
                                src={grafanaURL[10]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-4 col-xxl-4">
                            <iframe
                                src={grafanaURL[11]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-4 col-xxl-4">
                            <iframe
                                src={grafanaURL[12]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-6 col-xxl-6">
                            <iframe
                                src={grafanaURL[24]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-6 col-xxl-6">
                            <iframe
                                src={grafanaURL[25]}
                                width="100%" height="300px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[23]}
                                width="100%" height="250px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[22]}
                                width="100%" height="150px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[6]}
                                width="100%" height="500px" frameBorder="0"/>
                        </div>
                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[7]}
                                width="100%" height="600px" frameBorder="0"/>
                        </div>

                        <div className="col-lg-12 col-xxl-12">
                            <iframe
                                src={grafanaURL[18]}
                                width="100%" height="500px" frameBorder="0"/>
                        </div>

                    </div>

                    <br/>
                    <div className="fw-bolder bg-white card gs-0 gy-4 p-4">
                        <b>Additional Data</b>
                    </div>
                    <br/>

                    <div className="row">
                        {tableData[41] === "0" && <div className="col-lg-6 col-xxl-6 mb-2">
                            <div className="card card-custom card-stretch mb-2 p-4">
                                <strong style={{textAlign: 'center'}} className="pb-4">Motor Details</strong>
                                <table className="table table table-bordered">
                                    <tbody>
                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-barcode"></i></th>
                                        <td><strong>IMEI</strong></td>
                                        <td><strong>{tableData[0]}</strong></td>
                                    </tr>
                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fas fa-bolt"></i></th>
                                        <td><strong>Voltage</strong></td>
                                        <td>{tableData[10]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-broadcast-tower"></i></th>
                                        <td><strong>Current</strong></td>
                                        <td>{tableData[11]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-tachometer-alt"></i></th>
                                        <td><strong>Throttle</strong></td>
                                        <td>{tableData[12]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-thermometer"></i></th>
                                        <td><strong>Temperature (°C)</strong></td>
                                        <td>{tableData[13]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-thermometer-full"></i></th>
                                        <td><strong>Controller Temperature (°C)</strong></td>
                                        <td>{tableData[14]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-exclamation-triangle"></i>
                                        </th>
                                        <td><strong>Fault Code</strong></td>
                                        <td>{tableData[15]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-certificate"></i></th>
                                        <td><strong>RPM</strong></td>
                                        <td>{tableData[16]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-compass"></i></th>
                                        <td><strong>Gear</strong></td>
                                        <td>{tableData[17]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-globe"></i></th>
                                        <td><strong>Power Mode</strong></td>
                                        <td>{tableData[18]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-hand-spock"></i></th>
                                        <td><strong>Handle Opening</strong></td>
                                        <td>{tableData[19]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-grip-vertical"></i></th>
                                        <td><strong>Status</strong></td>
                                        <td>{tableData[20]}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>}

                        {tableData[41] === "1" && <div className="col-lg-6 col-xxl-6 mb-2">
                            <div className="card card-custom card-stretch mb-2 p-4">
                                <strong style={{textAlign: 'center'}} className="pb-4">Extra Details</strong>
                                <table className="table table table-bordered">
                                    <tbody>
                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-barcode"></i></th>
                                        <td><strong>Battery Derial No</strong></td>
                                        <td><strong>{tableData[21]}</strong></td>
                                    </tr>
                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fas fa-bolt"></i></th>
                                        <td><strong>BMS Software Version</strong></td>
                                        <td>{tableData[22]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-broadcast-tower"></i></th>
                                        <td><strong>Battery Alarams</strong></td>
                                        <td>{tableData[23]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-tachometer-alt"></i></th>
                                        <td><strong>Series Cell Qty</strong></td>
                                        <td>{tableData[24]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-thermometer"></i></th>
                                        <td><strong>Parallel Cell Qty</strong></td>
                                        <td>{tableData[25]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-thermometer-full"></i></th>
                                        <td><strong>Rated Energy</strong></td>
                                        <td>{tableData[26]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-exclamation-triangle"></i>
                                        </th>
                                        <td><strong>Deep Discharge Count</strong></td>
                                        <td>{tableData[27]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-certificate"></i></th>
                                        <td><strong>Cumulative Energy</strong></td>
                                        <td>{tableData[28]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-compass"></i></th>
                                        <td><strong>Default Operating Mode</strong></td>
                                        <td>{tableData[29]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-chess-king"></i></th>
                                        <td><strong>Present Energy</strong></td>
                                        <td>{tableData[30]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-chair"></i></th>
                                        <td><strong>Present Capacity</strong></td>
                                        <td>{tableData[39]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-globe"></i></th>
                                        <td><strong>Usable Energy</strong></td>
                                        <td>{tableData[32]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-circle"></i></th>
                                        <td><strong>Usable Capacity</strong></td>
                                        <td>{tableData[31]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-circle-notch"></i></th>
                                        <td><strong>Last Charge Count</strong></td>
                                        <td>{tableData[33]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-clone"></i></th>
                                        <td><strong>Reverse Mode Count</strong></td>
                                        <td>{tableData[34]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-cloud"></i></th>
                                        <td><strong>Over Charge Count</strong></td>
                                        <td>{tableData[35]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-clipboard-check"></i></th>
                                        <td><strong>Pack Voltage</strong></td>
                                        <td>{tableData[36]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-cocktail"></i></th>
                                        <td><strong>Cumulative Charge Energy</strong></td>
                                        <td>{tableData[37]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-compact-disc"></i></th>
                                        <td><strong>Cumulative Discharge Energy</strong></td>
                                        <td>{tableData[38]}</td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-cookie"></i></th>
                                        <td><strong>Present Capacity</strong></td>
                                        <td>{tableData[39]}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>}

                        {tableData[41] === "1" && <div className="col-lg-6 col-xxl-6 mb-2">
                            <div className="card card-custom card-stretch mb-2 p-4">
                                <strong style={{textAlign: 'center'}} className="pb-4">Extra Details</strong>
                                <table className="table table table-bordered">
                                    <tbody>
                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-cookie"></i></th>
                                        <td><strong>Cycle Count</strong></td>
                                        <td><strong>{tableData[54]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-power-off"></i></th>
                                        <td><strong>Last Charge Energy Cycle 1</strong></td>
                                        <td><strong>{tableData[42]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-power-off"></i></th>
                                        <td><strong>Last Charge Energy Cycle 2</strong></td>
                                        <td><strong>{tableData[43]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-power-off"></i></th>
                                        <td><strong>Last Charge Energy Cycle 3</strong></td>
                                        <td><strong>{tableData[44]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-power-off"></i></th>
                                        <td><strong>Last Charge Energy Cycle 4</strong></td>
                                        <td><strong>{tableData[45]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-swatchbook"></i></th>
                                        <td><strong>Last DisCharge Energy Cycle 2</strong></td>
                                        <td><strong>{tableData[46]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-swatchbook"></i></th>
                                        <td><strong>Last DisCharge Energy Cycle 4</strong></td>
                                        <td><strong>{tableData[47]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-swatchbook"></i></th>
                                        <td><strong>Last DisCharge Energy Cycle 3</strong></td>
                                        <td><strong>{tableData[48]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-swatchbook"></i></th>
                                        <td><strong>Last DisCharge Energy Cycle 4</strong></td>
                                        <td><strong>{tableData[49]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-expand"></i></th>
                                        <td><strong>Alarm 1</strong></td>
                                        <td><strong>{tableData[50]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-expand"></i></th>
                                        <td><strong>Alarm 2</strong></td>
                                        <td><strong>{tableData[51]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-expand"></i></th>
                                        <td><strong>Alarm 3</strong></td>
                                        <td><strong>{tableData[52]}</strong></td>
                                    </tr>

                                    <tr>
                                        <th width='10px' scope="row"><i className="fas fa-expand"></i></th>
                                        <td><strong>Alarm 4</strong></td>
                                        <td><strong>{tableData[53]}</strong></td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>}
                    </div>
                </>}
            </div>
        </div>
    </>);
}
