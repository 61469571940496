import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {ContentRoute, LayoutSplashScreen} from "../_metronic/layout";
import {OrgsPage} from "./pages/SuperAdmin/Orgs/OrgsPage";
import {AdminUsersPage} from "./pages/SuperAdmin/users/AdminUsersPage";
import {DashboardPage} from "./pages/dashboard/DashboardPage";
import {AdminDevicesPage} from "./pages/SuperAdmin/devices/AdminDevicesPage";
import {UsersPage} from "./pages/User/users/UsersPage";
import {GroupsPage} from "./pages/User/tags/GroupsPage";
import {DevicesPage} from "./pages/User/devices/DevicesPage";
import {DeviceDetailsPage} from "./pages/User/devices/DeviceDetailsPage";
import {TripsPage} from "./pages/User/trips/TripsPage";
import {DeviceLocation} from "./pages/User/map/DeviceLocation";
import {TripsDetailsPage} from "./pages/User/trips/TripsDetailsPage";
import {CommandsPage} from "./pages/User/commands/CommandsPage";
import {RoutePlaybackPage} from "./pages/User/devices/RoutePlaybackPage";
import {GeoFencePage} from "./pages/User/geofence/GeoFencePage";
import {GeoFenceAddEditPage} from "./pages/User/geofence/GeoFenceAddEditPage";
import {TripRoutePlaybackPage} from "./pages/User/devices/TripRoutePlaybackPage";
import {MovementReportsPage} from "./pages/Reports/MovementReportsPage";
import {SpeedAlertsReportsPage} from "./pages/Reports/SpeedAlertsReportsPage";
import {StatusReportsPage} from "./pages/Reports/StatusReportsPage";
import {MultipleDeviceLocation} from "./pages/User/map/MultipleDeviceLocation";
import {EVDeviceListPageDashboardHelper} from "./pages/User/dashboard/EVDeviceListPageDashboardHelper";
import {MulCommandsPage} from "./pages/User/commands/MulCommandsPage";
import {ApiKeyPage} from "./pages/User/apiKey/ApiKeyPage";
import {DownloadReportsPage} from "./pages/User/downloadReports/DownloadReportsPage";

export default function BasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>

                <Route path="/geofence/new">
                    {({history, match}) => (
                        <GeoFenceAddEditPage
                            history={history}
                            show={match != null}
                            id={match && match.params.id}
                        />
                    )}
                </Route>

                <Route path="/geofence/edit/:id">
                    {({history, match}) => (
                        <GeoFenceAddEditPage
                            history={history}
                            show={match != null}
                            id={match && match.params.id}
                        />
                    )}
                </Route>

                <Redirect exact from="/" to="/dashboard"/>

                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/admin/orgs" component={OrgsPage}/>
                <ContentRoute path="/admin/users" component={AdminUsersPage}/>
                <ContentRoute path="/admin/devices" component={AdminDevicesPage}/>

                <ContentRoute path="/users" component={UsersPage}/>
                <ContentRoute path="/geofence" component={GeoFencePage}/>
                <ContentRoute path="/groups" component={GroupsPage}/>
                <ContentRoute path="/devices" component={DevicesPage}/>

                <ContentRoute path="/movementReport" component={MovementReportsPage}/>
                <ContentRoute path="/overSpeedReport" component={SpeedAlertsReportsPage}/>
                <ContentRoute path="/statusReport" component={StatusReportsPage}/>

                <ContentRoute path="/trips" component={TripsPage}/>
                <ContentRoute path="/downloadReports" component={DownloadReportsPage}/>
                <ContentRoute path="/viewMore" component={EVDeviceListPageDashboardHelper}/>
                <ContentRoute path="/map" component={MultipleDeviceLocation}/>
                <ContentRoute path="/mulCommands" component={MulCommandsPage}/>
                <ContentRoute path="/apiKey" component={ApiKeyPage}/>

                <Route path="/location/:id">
                    {({history, match}) => (
                        <DeviceLocation
                            history={history}
                            show={match != null}
                            id={match && match.params.id}
                        />
                    )}
                </Route>

                <Route path="/deviceDetails/:id">
                    {({history, match}) => (
                        <DeviceDetailsPage
                            history={history}
                            show={match != null}
                            id={match && match.params.id}
                        />
                    )}
                </Route>

                <Route path="/tripDetailsPage/:id">
                    {({history, match}) => (
                        <TripsDetailsPage
                            history={history}
                            show={match != null}
                            id={match && match.params.id}
                        />
                    )}
                </Route>

                <Route path="/commands/:id">
                    {({history, match}) => (
                        <CommandsPage
                            history={history}
                            show={match != null}
                            id={match && match.params.id}
                        />
                    )}
                </Route>

                <Route path="/routePlayback/:id">
                    {({history, match}) => (
                        <RoutePlaybackPage
                            history={history}
                            show={match != null}
                            id={match && match.params.id}
                        />
                    )}
                </Route>

                <Route path="/tripRoutePlayback/:id">
                    {({history, match}) => (
                        <TripRoutePlaybackPage
                            history={history}
                            show={match != null}
                            id={match && match.params.id}
                        />
                    )}
                </Route>

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
