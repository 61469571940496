import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Common/auth/context/AuthState";
import { useSubheader } from "../../../../_metronic/layout";
import "react-datepicker/dist/react-datepicker.css";
import {DownloadReportDateRangeSelector} from "./DownloadReportDateRangeSelector";

const cardsData = [
    {
        title: "Movement Report",
        description: "Generate a detailed report of vehicle movements, including date, vehicle number, start point, start time, end point, end time, duration, distance traveled, and cumulative distance.",
        type: 1,
    },
    {
        title: "Stoppage Report",
        description: "Identify and analyze vehicle stoppages, including date & time, vehicle number, location, and duration.",
        type: 2,
    },
    {
        title: "Idling Report",
        description: "Monitor and track vehicle idling times, including date & time, vehicle number, location, duration, and cumulative idling time.",
        type: 3,
    },
    {
        title: "Over Speed Report",
        description: "Detect and report instances where vehicles exceed speed limits, including date & time, vehicle number, start time, end time, overspeed duration, distance, and maximum speed.",
        type: 4,
    },
    {
        title: "Vehicle Summary Report",
        description: "Generate a comprehensive overview of vehicle performance, including serial number, vehicle number, IMEI number, start date/time, end date/time, location, distance traveled, stoppage time, ignition ON time, idling time, and cumulative distance.",
        type: 5,
    },
    {
        title: "Geo Fence Entry & Exit",
        description: "Track vehicle entry and exit events based on predefined geographical boundaries, including vehicle number, date, entry time, exit time, location, and duration.",
        type: 6,
    }
];

export function DownloadReportsPage({ history }) {
    const { auth, setTitleButtonGotoCallback } = useContext(AuthContext);
    const subHeader = useSubheader();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCardType, setSelectedCardType] = useState(null);

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");

        subHeader.setTitle("Download Reports");
        subHeader.setBreadcrumbs([
            {
                title: "Download Reports",
            },
        ]);
    }, [auth]);

    function openModal(type) {
        setSelectedCardType(type);
        setIsModalOpen(true);
    }

    function closeModal() {
        setIsModalOpen(false);
        setSelectedCardType(null);
    }

    function generateReport() {
        console.log(`Generate Report: ${selectedCardType}`);
        closeModal();
    }

    return (
        <div>
            <div className="row">
                {cardsData.map((card, index) => (
                    <div key={index} className="col-xl-4">
                        <div className="card card-custom gutter-b" style={{ height: 350 }}>
                            <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
                                <div className="mr-2">
                                    <h3 className="font-weight-bolder">{card.title}</h3>
                                    <div className="text-dark-50 font-size-lg mt-2">
                                        {card.description}
                                    </div>
                                </div>
                                <a
                                    onClick={() => openModal(card.type)}
                                    className="btn btn-primary font-weight-bold py-3 px-6"
                                >
                                    Download Report
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <DownloadReportDateRangeSelector
                id={selectedCardType}
                show={isModalOpen}
                onHide={closeModal}
                reloadData={generateReport}
            />
        </div>
    );
}