import React, {useContext, useEffect, useState} from "react";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {HrefColumnFormatter} from "../../Common/column-formatters/HrefColumnFormatter";
import {apiGetAllGroups, DASHBOARD} from "../../crud";
import {defaultGroups, defaultList} from "../../Common/generic/table/InitialStates";
import {GenericTable} from "../../Common/generic/table/GenericTable";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {useSubheader} from "../../../../_metronic/layout";
import queryString from 'query-string';
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {Formik} from "formik";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import {DateTimeColumnFormatter} from "../../Common/column-formatters/DateTimeColumnFormatter";
import {ActionsColumnFormatter} from "../../Common/column-formatters/ActionsColumnFormatter";
import {isMobile} from "react-device-detect";
import {Route, useLocation} from "react-router-dom";
import {ShareLocationDialog} from "./ShareLocationDialog";


export function EVDeviceListPageDashboardHelper({history}) {
    const [reload, setReload] = useState(0);
    const {setTitleButtonGotoCallback} = useContext(AuthContext);
    const subHeader = useSubheader();
    const [params, setParams] = useState(queryString.parse(history.location.search));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [allGroups, setAllGroups] = useState(defaultGroups);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const {isAdmin} = useContext(AuthContext);
    const location = useLocation();

    useEffect(() => {
        let isMounted = true;

        // Function to update the params state
        const updateParamsAndDate = () => {
            if (isMounted) {
                const newParams = queryString.parse(history.location.search);
                setParams(newParams);

                // Set the date
                if (newParams.date) {
                    const parsedDate = new Date(newParams.date);
                    if (!isNaN(parsedDate)) {
                        setSelectedDate(parsedDate);
                    } else {
                        setSelectedDate(new Date()); // Fallback to current date if parsing fails
                    }
                } else {
                    setSelectedDate(new Date()); // Fallback if no date in params
                }

                setSelectedGroup([]); // Reset the selected group
            }
        };

        // Listen for changes in the URL
        const unlisten = history.listen((location, action) => {
            if (action === 'PUSH' || action === 'POP') {
                updateParamsAndDate();
            }
        });

        // Call it once to set initial state
        updateParamsAndDate();

        return () => {
            isMounted = false;
            unlisten(); // Stop listening to history changes
        };
    }, [history]);

    useEffect(() => {
        reloadData();
    }, [params]);

    useEffect(() => {
        if (params.groups) {
            const groupIds = params.groups.split(',');
            const selectedGroups = allGroups.filter(group => groupIds.includes(group.id));
            setSelectedGroup(selectedGroups);
        }
    }, [params.groups, allGroups]); // This effect runs whenever params.groups or allGroups changes

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");

        subHeader.setTitle(`Vehicle List - ${params.title}`);
        subHeader.setBreadcrumbs([{
            "pathname": "/dashboard", "title": "Dashboard"
        }, {
            "title": "Vehicle List"
        }]);

    }, [reload]);

    useEffect(() => {
        let isMounted = true;

        if (params.date && isMounted) {
            const parsedDate = new Date(params.date);
            if (!isNaN(parsedDate)) {
                setSelectedDate(parsedDate);
            }
        }

        return () => {
            isMounted = false;
        };
    }, [params.date]);

    useEffect(() => {
        let isMounted = true;

        apiGetAllGroups().then(({data}) => {
            if (isMounted) {
                setAllGroups(data.entities);
            }
        }).catch(() => {
            // Handle error
        });

        return () => {
            isMounted = false;
        };
    }, []);

    function openChild(id) {
        history.push("/deviceDetails/" + id);
    }

    function openMap(id) {
        history.push("/location/" + id);
    }

    function openFullMap() {
        if (params.date instanceof Date) {
            history.push(`/map?groups=${params.groups}&date=${params.date.toISOString()}&seriesName=${params.seriesName}`);
        } else {
            history.push(`/map?groups=${params.groups}&date=${params.date}&seriesName=${params.seriesName}`);
        }
    }

    function reloadData() {
        setReload(reload => reload + 1);
    }

    function getColumns() {
        if (params.seriesName === "Distance Travelled") {
            return [{
                dataField: "id",
                text: "IMEI",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                hidden: false,
                formatter: HrefColumnFormatter,
                formatExtraData: {
                    openChild: openChild, type: 1
                },
            }, {
                dataField: "vehicleNumber",
                text: "VEHICLE NUMBER",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
            }, {
                dataField: "startOdoStr",
                text: "START ODOMETER",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
            }, {
                dataField: "endOdoStr", text: "END ODOMETER", sort: false, sortCaret: sortCaret, headerSortingClasses,
            }, {
                dataField: "totalDistanceStr",
                text: "Total Distance",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
            },]
        } else if (params.seriesName === "Over Speeding Vehicles") {
            return [{
                dataField: "imei",
                text: "IMEI",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                hidden: false,
                formatter: HrefColumnFormatter,
                formatExtraData: {
                    openChild: openChild, type: 1
                },
            }, {
                dataField: "vehicleNumber",
                text: "VEHICLE NUMBER",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
            }, {
                dataField: "address", text: "ADDRESS", sort: true, sortCaret: sortCaret, headerSortingClasses,
            }, {
                dataField: "alertTime",
                text: "START TIME",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: DateTimeColumnFormatter,
            }, {
                dataField: "durationInMins",
                text: "DURATION",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: (cell) => `${cell} mins`,
            }, {
                dataField: "distance",
                text: "DISTANCE TRAVELLED",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: (cell) => `${cell} kms`,
            }, {
                dataField: "speed",
                text: "SPEED",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: (cell) => `${cell} km/h`,
            }, {
                dataField: "odoReading",
                text: "ODOMETER",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: (cell) => `${cell} kms`,
            }]
        } else if (params.seriesName === "Moving/Idle Vehicles") {
            return [{
                dataField: "imei",
                text: "IMEI",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
                hidden: false,
                formatter: HrefColumnFormatter,
                formatExtraData: {
                    openChild: openChild, type: 1
                },
            }, {
                dataField: "vehicleNumber",
                text: "VEHICLE NUMBER",
                sort: false,
                sortCaret: sortCaret,
                headerSortingClasses,
            }, {
                dataField: "idleTime",
                text: "IDLE DURATION",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: (cell) => `${cell} mins`,
            }, {
                dataField: "movingTime",
                text: "MOVING DURATION",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: (cell) => `${cell} mins`,
            }]
        }

        return [{
            dataField: "imei",
            text: "IMEI",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: false,
            formatter: HrefColumnFormatter,
            formatExtraData: {
                openChild: openChild, type: 1
            },
        }, {
            dataField: "vehicleNumber",
            text: "VEHICLE NUMBER",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: false,
        }, {
            dataField: "speed", text: "SPEED", sort: false, sortCaret: sortCaret, headerSortingClasses,
        }, {
            dataField: "odometer", text: "ODOMETER", sort: false, sortCaret: sortCaret, headerSortingClasses,
        }, {
            dataField: "soc",
            text: "SOC",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: (params.orgType !== "EV"),

        }, {
            dataField: "status",
            text: "BATTERY STATUS",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: (params.orgType !== "EV"),
        }, {
            dataField: "voltage",
            text: "VOLTAGE",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        }, {
            dataField: "locationLogDate",
            text: "LAST GPS DATA",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        }, {
            dataField: "batteryLogDate",
            text: "LAST BATTERY DATA",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: (params.orgType !== "EV"),
        }, {
            dataField: "dop",
            text: "IMB STATUS",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },{
            dataField: "intVlt",
            text: "INTERNAL BATTERY",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },{
            dataField: "address", text: "Address", sort: false, sortCaret: sortCaret, headerSortingClasses,
        }, {
            dataField: "action", text: "Actions", formatter: ActionsColumnFormatter, formatExtraData: {
                buttons: [{
                    name: "Share Location",
                    color: "primary",
                    icon: "/media/svg/icons/Navigation/Sign-out.svg",
                    method: openShareDialog
                }],
            }, classes: "text-right pr-0", headerClasses: "text-right pr-3", style: {
                minWidth: "100px",
            }, hidden: isMobile
        },]
    }

    function openShareDialog(id = "w24352") {
        history.push(`/viewMore/shareLocaton/${id}`);
    }

    return (<>
        <Route path="/viewMore/shareLocaton/:id">
            {({history, match}) => {
                // Construct the URL using the current query parameters
                const query = new URLSearchParams(location.search);
                const groups = query.get('groups') || '';
                const title = query.get('title') || 'All Devices';
                const date = query.get('date') || new Date().toISOString();
                const seriesName = query.get('seriesName') || 'All Devices';
                const orgType = query.get('orgType') || 'EV';

                const returnUrl = `/viewMore?groups=${groups}&title=${title}&date=${date}&seriesName=${seriesName}&orgType=${orgType}#`;

                return (<ShareLocationDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push(returnUrl);
                        }}
                        reloadData={reloadData}
                    />);
            }}
        </Route>

        <div className="py-5 h-auto mb-12">
            <Formik
                initialValues={{}}
                onSubmit={(values) => {

                    let groups = "";
                    for (const group in selectedGroup) {
                        groups = groups + selectedGroup[group].id + ",";
                    }

                    setParams({
                        ...params, date: selectedDate, groups: groups
                    });

                    reloadData()
                }}
            >
                {({
                      values, handleSubmit, handleBlur, handleChange, setFieldValue,
                  }) => (<form onSubmit={handleSubmit} className="form form-label-right col-sm-12">
                    <div className="form-group row d-flex justify-content-end">

                        <div className="col-lg-3">
                            {params.seriesName !== "Distance Travelled" && <>
                                <button
                                    type="button" onClick={() => openFullMap()}
                                    className="btn btn-sm btn-light-primary font-weight-bold">
                                    <span className="flaticon2-map"> &nbsp;&nbsp;View On Map</span>
                                </button>
                                <small className="form-text text-muted">
                                    View All the below listed devices on Map
                                </small>
                            </>}
                        </div>

                        <div className="col-lg-3">
                        </div>

                        <div className="col-lg-4">
                            {isAdmin() && <AsyncSelect
                                isMulti={true}
                                id="group"
                                defaultOptions={allGroups}
                                value={selectedGroup}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                    setSelectedGroup(e);
                                    handleSubmit();
                                }}
                            />}

                        </div>

                        <div className="col-lg-2">
                            <DatePicker className="form-control"
                                        selected={selectedDate}
                                        dateFormat="dd MMM yyyy"
                                        onChange={(e) => {
                                            setSelectedDate(e);
                                            handleSubmit();
                                        }}
                            />

                            <small className="form-text text-muted">
                                <b>Filter</b> by Date
                            </small>
                        </div>
                    </div>
                </form>)}
            </Formik>
        </div>
        <Card>
            <CardBody>

                <GenericTable reload={reload}
                              tableFilterData={params}
                              defaultData={defaultList}
                              columns={getColumns()}
                              recordCount={100}
                              url={DASHBOARD + "/evAllDatalistData"}
                />
            </CardBody>

        </Card>
    </>);
}
