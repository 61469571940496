import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {useSubheader} from "../../../../_metronic/layout";
import {Circle, MapContainer, useMapEvents, useMap} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import {GOOGLE_MAPS_KEY} from "../../Constants";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import {Button, Form, FormControl, InputGroup, Pagination, Table} from "react-bootstrap";
import {
    apiGeoFenceCreate,
    apiUntagFromGeoFence,
    apiUpdateGeoFenceById,
    getGeoFenceByID,
    getGeoFenceTags
} from "../../crud";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS, TOAST_WARNING} from "../../Utils/toast/Toast";
import {defaultGeoFence} from "../../Common/generic/table/InitialStates";

export function GeoFenceAddEditPage({history, id}) {
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);
    const subHeader = useSubheader();
    const [map, setMap] = useState(null);

    const [center, setCenter] = useState([12.9716, 77.5946]);
    const [radius, setRadius] = useState(3000);
    const [visible, setVisible] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [detectMoveIn, setDetectMoveIn] = useState(false);
    const [detectMoveOut, setDetectMoveOut] = useState(false);
    const [geoFenceName, setGeoFenceName] = useState("");
    const [geoFenceForEdit, setGeoFenceForEdit] = useState(defaultGeoFence);
    const [taggedIMEIs, setTaggedIMEIs] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");
        if (!id) {
            subHeader.setTitle("New Geo Fence");
        } else {
            subHeader.setTitle("Edit Geo Fence");
        }
        subHeader.setBreadcrumbs([
            {
                pathname: "/geofence",
                title: "Geo Fence"
            },
            {
                title: "Geo Fence Details"
            }
        ]);
    }, [auth]);

    useEffect(() => {
        if (id != null) {
            getGeoFenceByID(id).then(
                ({data}) => {
                    setGeoFenceForEdit(data);
                    setCenter([data.latitude, data.longitude]);
                    setRadius(data.range);
                    setGeoFenceName(data.name);
                    setDetectMoveIn(data.detectMoveIn);
                    setDetectMoveOut(data.detectMoveOut);
                    setVisible(true);
                }).catch(() => {
            });

            getGeoFenceTags(id).then(
                ({data}) => {
                    const imeiList = data.entities.map(rec => rec.imei);
                    setTaggedIMEIs(imeiList)
                }).catch(() => {
            });
        } else {
            setGeoFenceForEdit(defaultGeoFence);
        }
    }, [id]);

    const MapClick = () => {
        useMapEvents({
            click: (event) => {
                if (!registered) {
                    setRegistered(true);
                }
                setCenter([event.latlng.lat, event.latlng.lng]);
                setVisible(true);
            }
        });

        return null;
    };

    const Recenter = ({lat, lng}) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng], map.getZoom(), {animate: true});
        }, [lat, lng]);
        return null;
    };

    useEffect(() => {
        if (map && visible) {
            map.setView(center, map.getZoom(), {animate: true});
        }
    }, [map, visible, center]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const geoFenceData = {
            id: id,
            name: geoFenceName,
            latitude: center[0],
            longitude: center[1],
            range: radius,
            detectMoveIn: detectMoveIn,
            detectMoveOut: detectMoveOut,
        };

        if (center[0] === 0 || center[1] === 0) {
            showToast("Please Select a point on the Map", TOAST_WARNING);
            return;
        }

        if (id) {
            apiUpdateGeoFenceById(geoFenceData)
                .then(() => {
                    showToast("GeoFence Updated", TOAST_SUCCESS);
                })
                .catch(() => {
                    showToast("GeoFence Update Failed", TOAST_FAILURE);
                })
                .finally(() => {
                    history.push("/geofence");
                });
        } else {
            apiGeoFenceCreate(geoFenceData)
                .then(() => {
                    showToast("GeoFence Created", TOAST_SUCCESS);
                })
                .catch(() => {
                    showToast("GeoFence Creation Failed", TOAST_FAILURE);
                })
                .finally(() => {
                    history.push("/geofence");
                });
        }
    };

    const untagIMEI = (imei) => {
        setTaggedIMEIs(taggedIMEIs.filter(item => item !== imei));

        apiUntagFromGeoFence(id, imei)
            .then((data) => {
                showToast("IMEI" + imei + " is Untagged", TOAST_SUCCESS);
            })
            .catch(() => {
                showToast("IMEI" + imei + " Untag Failed", TOAST_FAILURE);
            });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = taggedIMEIs.filter(imei => imei.includes(searchTerm)).slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(taggedIMEIs.filter(imei => imei.includes(searchTerm)).length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="row g-5" style={{height: '100%'}}>
            <div className="col-lg-8">
                <div className="card card-custom card-stretch shadow mb-5">
                    <div className="card-header">
                        <h3 className="card-title">Location</h3>
                    </div>
                    <div className="card-body">
                        <MapContainer
                            zoom={11}
                            center={center}
                            scrollWheelZoom={false}
                            whenCreated={setMap}
                            style={{height: "100%", width: "100%"}}>

                            {visible && (
                                <>
                                    <Circle center={center} radius={radius}/>
                                    <Recenter lat={center[0]} lng={center[1]}/>
                                </>
                            )}
                            <MapClick/>
                            <ReactLeafletGoogleLayer apiKey={GOOGLE_MAPS_KEY}/>
                        </MapContainer>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="card card-custom card-stretch shadow mb-5">
                    <div className="card-header">
                        <h3 className="card-title">Configure Geo Fence</h3>
                    </div>
                    <div className="card-body">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="row">
                                <Form.Label className="col-12">Current Selected Location:</Form.Label>
                                <Form.Text
                                    className="text-muted col-12">&nbsp;&nbsp;&nbsp;&nbsp;{center[0]}, {center[1]}</Form.Text>
                            </Form.Group>

                            <Form.Group className="row">
                                <div className="col-12">
                                    <Form.Label>Geo Fence Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="geo-fence-name"
                                        value={geoFenceName}
                                        onChange={(e) => setGeoFenceName(e.target.value)}
                                        required
                                    />
                                    <Form.Text className="text-muted">Name your Geofence</Form.Text>
                                </div>
                            </Form.Group>

                            <Form.Group className="row">
                                <div className="col-12">
                                    <Form.Label>Range in Meters</Form.Label>
                                    <InputRange
                                        maxValue={50000}
                                        minValue={500}
                                        value={radius}
                                        onChange={value => setRadius(value)}/>
                                    <Form.Text className="text-muted">Set the radius for your Geofence</Form.Text>
                                </div>
                            </Form.Group>

                            <Form.Group className="row">
                                <div className="col-12">
                                    <Form.Check
                                        type="checkbox"
                                        id="detectMoveIn"
                                        label="Detect Move in to GeoFence"
                                        checked={detectMoveIn}
                                        onChange={() => setDetectMoveIn(!detectMoveIn)}
                                    />
                                    <Form.Text className="text-muted">Enable to detect when a vehicle enters the
                                        geofence</Form.Text>
                                </div>
                            </Form.Group>

                            <Form.Group className="row">
                                <div className="col-12">
                                    <Form.Check
                                        type="checkbox"
                                        id="detectMoveOut"
                                        label="Detect Move Out of GeoFence"
                                        checked={detectMoveOut}
                                        onChange={() => setDetectMoveOut(!detectMoveOut)}
                                    />
                                    <Form.Text className="text-muted">Enable to detect when a vehicle leaves the
                                        geofence</Form.Text>
                                </div>
                            </Form.Group>

                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-12 text-right">
                                        <Button type="submit" className="btn btn-success mr-2">Submit</Button>
                                        <Button type="reset" className="btn btn-secondary">Cancel</Button>
                                    </div>
                                </div>
                            </div>
                        </Form>

                        <br/>
                        <h3>Tagged Vehicles</h3>
                        <hr/>
                        {currentItems.length === 0 ? (
                            <div className="alert alert-custom alert-light-primary font-weight-bold my-10">
                                <div className="alert-text">
                                    There are no IMEIs/Vehicles tagged to this GeoFence yet. Please go to the GeoFence
                                    Listing page to tag vehicles to this GeoFence.
                                </div>
                            </div>
                        ) : (
                            <>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="Search by IMEI"
                                        aria-label="Search by IMEI"
                                        aria-describedby="basic-addon2"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </InputGroup>
                                <Table striped bordered hover className="mt-4">
                                    <thead>
                                    <tr>
                                        <th>IMEI</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentItems.map((imei, index) => (
                                        <tr key={index}>
                                            <td>{imei}</td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    title="UnTag this IMEI"
                                                    onClick={() => untagIMEI(imei)}
                                                >
                                                    X
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </>
                        )}

                        <Pagination>
                            {pageNumbers.map(number => (
                                <Pagination.Item key={number} active={number === currentPage}
                                                 onClick={() => paginate(number)}>
                                    {number}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </div>
                </div>
            </div>
        </div>
    );
}
